"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.childsizechart = exports.womanSizeChart = exports.manSizeChart = void 0;
exports.manSizeChart = [
    {
        usa: "3½", china: 35, europe: 35, russia: 34, japan: 21.5, brazil: 33,
        uk: 3, southKorea: 228, mexico: null, australia: 3, mondopoint: 228,
        centimeters: 22.8, inches: 9
    },
    {
        usa: 4, china: 36, europe: "35½", russia: 34.5, japan: 22, brazil: 33.5,
        uk: "3½", southKorea: 231, mexico: null, australia: "3½", mondopoint: 231,
        centimeters: 23.1, inches: "9⅛"
    },
    {
        usa: "4½", china: 37, europe: 36, russia: 35, japan: 22.5, brazil: 34,
        uk: 4, southKorea: 235, mexico: null, australia: 4, mondopoint: 235,
        centimeters: 23.5, inches: "9¼"
    },
    {
        usa: 5, china: 38, europe: 37, russia: 36, japan: 23, brazil: 35,
        uk: "4½", southKorea: 238, mexico: 4.5, australia: "4½", mondopoint: 238,
        centimeters: 23.8, inches: "9⅜"
    },
    {
        usa: "5½", china: 39, europe: "37½", russia: 36.5, japan: 23.5, brazil: 35.5,
        uk: 5, southKorea: 241, mexico: 5, australia: 5, mondopoint: 241,
        centimeters: 24.1, inches: "9½"
    },
    {
        usa: 6, china: 39.5, europe: 38, russia: 37, japan: 24, brazil: 36,
        uk: "5½", southKorea: 245, mexico: 5.5, australia: "5½", mondopoint: 245,
        centimeters: 24.5, inches: "9⅝"
    },
    {
        usa: "6½", china: 40, europe: "38½", russia: 37.5, japan: 24.5, brazil: 36.5,
        uk: 6, southKorea: 248, mexico: 6, australia: 6, mondopoint: 248,
        centimeters: 24.8, inches: "9¾"
    },
    {
        usa: 7, china: 41, europe: 39, russia: 38, japan: 25, brazil: 37,
        uk: "6½", southKorea: 251, mexico: 6.5, australia: "6½", mondopoint: 251,
        centimeters: 25.1, inches: "9⅞"
    },
    {
        usa: "7½", china: 41.5, europe: 40, russia: 39, japan: 25.5, brazil: 38,
        uk: 7, southKorea: 254, mexico: 7, australia: 7, mondopoint: 254,
        centimeters: 25.4, inches: 10
    },
    {
        usa: 8, china: 42, europe: 41, russia: 40, japan: 26, brazil: 39,
        uk: "7½", southKorea: 257, mexico: 7.5, australia: "7½", mondopoint: 257,
        centimeters: 25.7, inches: "10⅛"
    },
    {
        usa: "8½", china: 43, europe: 42, russia: 41, japan: 26.5, brazil: 40,
        uk: 8, southKorea: 260, mexico: 9, australia: 8, mondopoint: 260,
        centimeters: 26, inches: "10¼"
    },
    {
        usa: 9, china: 43.5, europe: 43, russia: 42, japan: 27, brazil: 41,
        uk: "8½", southKorea: 263, mexico: null, australia: "8½", mondopoint: 263,
        centimeters: 26.3, inches: "10⅜"
    },
    {
        usa: "9½", china: 44, europe: "43½", russia: 42.5, japan: 27.5, brazil: 41.5,
        uk: 9, southKorea: 267, mexico: 10, australia: 9, mondopoint: 267,
        centimeters: 26.7, inches: "10½"
    },
    {
        usa: 10, china: 44.5, europe: 44, russia: 43, japan: 28, brazil: 42,
        uk: "9½", southKorea: 270, mexico: null, australia: "9½", mondopoint: 270,
        centimeters: 27, inches: "10⅝"
    },
    {
        usa: "10½", china: 45, europe: "44½", russia: 43.5, japan: 28.5, brazil: 42.5,
        uk: 10, southKorea: 273, mexico: 11, australia: 10, mondopoint: 273,
        centimeters: 27.3, inches: "10¾"
    },
    {
        usa: 11, china: 46, europe: 45, russia: 44, japan: 29, brazil: 43,
        uk: "10½", southKorea: 276, mexico: null, australia: "10½", mondopoint: 276,
        centimeters: 27.6, inches: "10⅞"
    },
    {
        usa: "11½", china: 46.5, europe: "45½", russia: 44.5, japan: 29.5, brazil: 43.5,
        uk: 11, southKorea: 279, mexico: 12.5, australia: 11, mondopoint: 279,
        centimeters: 27.9, inches: 11
    },
    {
        usa: 12, china: 47, europe: 46, russia: 45, japan: 30, brazil: 44,
        uk: "11½", southKorea: 283, mexico: null, australia: "11½", mondopoint: 283,
        centimeters: 28.3, inches: "11⅛"
    },
    {
        usa: "12½", china: 47.5, europe: "46½", russia: 45.5, japan: 30.5, brazil: 44.5,
        uk: 12, southKorea: 286, mexico: null, australia: 12, mondopoint: 286,
        centimeters: 28.6, inches: "11¼"
    },
    {
        usa: 13, china: 48, europe: 47, russia: 46, japan: 31, brazil: 45,
        uk: "12½", southKorea: 289, mexico: null, australia: "12½", mondopoint: 289,
        centimeters: 28.9, inches: "11⅜"
    },
    {
        usa: "13½", china: null, europe: "47½", russia: 46.5, japan: 31.5, brazil: 45.5,
        uk: 13, southKorea: 292, mexico: null, australia: 13, mondopoint: 292,
        centimeters: 29.2, inches: "11½"
    }
];
exports.womanSizeChart = [
    {
        usa: 5, china: 35.5, europe: 35, russia: 34, japan: 21, brazil: 33,
        uk: "2½", southKorea: 228, mexico: null, australia: "3½", mondopoint: 228,
        centimeters: 22.8, inches: 9
    },
    {
        usa: "5½", china: 36, europe: "35½", russia: 34.5, japan: 21.5, brazil: 33.5,
        uk: 3, southKorea: 231, mexico: null, australia: 4, mondopoint: 231,
        centimeters: 23.1, inches: "9⅛"
    },
    {
        usa: 6, china: 37, europe: 36, russia: 35, japan: 22, brazil: 34,
        uk: "3½", southKorea: 235, mexico: null, australia: "4½", mondopoint: 235,
        centimeters: 23.5, inches: "9¼"
    },
    {
        usa: "6½", china: 37.5, europe: 37, russia: 36, japan: 22.5, brazil: 35,
        uk: 4, southKorea: 238, mexico: null, australia: 5, mondopoint: 238,
        centimeters: 23.8, inches: "9⅜"
    },
    {
        usa: 7, china: 38, europe: "37½", russia: 36.5, japan: 23, brazil: 35.5,
        uk: "4½", southKorea: 241, mexico: null, australia: "5½", mondopoint: 241,
        centimeters: 24.1, inches: "9½"
    },
    {
        usa: "7½", china: 39, europe: 38, russia: 37, japan: 23.5, brazil: 36,
        uk: 5, southKorea: 245, mexico: 4.5, australia: 6, mondopoint: 245,
        centimeters: 24.5, inches: "9⅝"
    },
    {
        usa: 8, china: 39.5, europe: "38½", russia: 37.5, japan: 24, brazil: 36.5,
        uk: "5½", southKorea: 248, mexico: 5, australia: "6½", mondopoint: 248,
        centimeters: 24.8, inches: "9¾"
    },
    {
        usa: "8½", china: 40, europe: 39, russia: 38, japan: 24.5, brazil: 37,
        uk: 6, southKorea: 251, mexico: 5.5, australia: 7, mondopoint: 251,
        centimeters: 25.1, inches: "9⅞"
    },
    {
        usa: 9, china: 41, europe: 40, russia: 39, japan: 25, brazil: 38,
        uk: "6½", southKorea: 254, mexico: 6, australia: "7½", mondopoint: 254,
        centimeters: 25.4, inches: 10
    },
    {
        usa: "9½", china: 41.5, europe: 41, russia: 40, japan: 25.5, brazil: 39,
        uk: 7, southKorea: 257, mexico: 6.5, australia: 8, mondopoint: 257,
        centimeters: 25.7, inches: "10⅛"
    },
    {
        usa: 10, china: 42, europe: 42, russia: 41, japan: 26, brazil: 40,
        uk: "7½", southKorea: 260, mexico: 7, australia: "8½", mondopoint: 260,
        centimeters: 26, inches: "10¼"
    },
    {
        usa: "10½", china: 43, europe: 43, russia: 42, japan: 27, brazil: 41,
        uk: 8, southKorea: 267, mexico: 7.5, australia: 9, mondopoint: 267,
        centimeters: 26.7, inches: "10½"
    },
    {
        usa: 12, china: 44.5, europe: 44, russia: 43, japan: 28, brazil: 42,
        uk: "9½", southKorea: 273, mexico: 9, australia: "10½", mondopoint: 273,
        centimeters: 27.3, inches: "10¾"
    },
    {
        usa: 13, china: 46, europe: 45, russia: 44, japan: 29, brazil: 43,
        uk: "10½", southKorea: 279, mexico: 10, australia: "11½", mondopoint: 279,
        centimeters: 27.9, inches: 11
    },
    {
        usa: 14, china: 47, europe: "46½", russia: 45.5, japan: 30, brazil: 44.5,
        uk: "11½", southKorea: 286, mexico: 11, australia: "12½", mondopoint: 286,
        centimeters: 28.6, inches: "11¼"
    },
    {
        usa: "15½", china: 48, europe: "48½", russia: 47.5, japan: 31, brazil: 46.5,
        uk: 13, southKorea: 292, mexico: 12.5, australia: 14, mondopoint: 292,
        centimeters: 29.2, inches: "11½"
    }
];
exports.childsizechart = [
    {
        usa: "½", china: 15, europe: "15½", russia: null, japan: 8, brazil: 13.5,
        uk: 0, southKorea: 83, mexico: null, australia: 0, mondopoint: 83,
        centimeters: 8.3, inches: "3¼"
    },
    {
        usa: 1, china: 15.5, europe: 16, russia: null, japan: 8.5, brazil: 14,
        uk: "½", southKorea: 89, mexico: 9, australia: "½", mondopoint: 89,
        centimeters: 8.9, inches: "3½"
    },
    {
        usa: "1½", china: 16, europe: "16½", russia: null, japan: 9, brazil: 14.5,
        uk: 1, southKorea: 92, mexico: null, australia: 1, mondopoint: 92,
        centimeters: 9.2, inches: "3⅝"
    },
    {
        usa: 2, china: 16.5, europe: 17, russia: null, japan: 9.5, brazil: 15,
        uk: 1, southKorea: 95, mexico: 10, australia: 1, mondopoint: 95,
        centimeters: 9.5, inches: "3¾"
    },
    {
        usa: "2½", china: 17, europe: "17½", russia: null, japan: 10, brazil: 15.5,
        uk: "1½", southKorea: 102, mexico: null, australia: "1½", mondopoint: 102,
        centimeters: 10.2, inches: 4
    },
    {
        usa: 3, china: 18, europe: 18, russia: 17, japan: 10.5, brazil: 16,
        uk: 2, southKorea: 105, mexico: 11, australia: 2, mondopoint: 105,
        centimeters: 10.5, inches: "4⅛"
    },
    {
        usa: "3½", china: 19, europe: "18½", russia: 18, japan: 11, brazil: 16.5,
        uk: "2½", southKorea: 108, mexico: null, australia: "2½", mondopoint: 108,
        centimeters: 10.8, inches: "4¼"
    },
    {
        usa: 4, china: 19.5, europe: 19, russia: 19, japan: 11.5, brazil: 17,
        uk: 3, southKorea: 114, mexico: 12, australia: 3, mondopoint: 114,
        centimeters: 11.4, inches: "4½"
    },
    {
        usa: "4½", china: 20, europe: "19½", russia: 19.5, japan: 12, brazil: 17.5,
        uk: "3½", southKorea: 117, mexico: null, australia: "3½", mondopoint: 117,
        centimeters: 11.7, inches: "4⅝"
    },
    {
        usa: 5, china: 20.5, europe: 20, russia: 19.5, japan: 12.5, brazil: 18,
        uk: 4, southKorea: 121, mexico: 12.5, australia: 4, mondopoint: 121,
        centimeters: 12.1, inches: "4¾"
    },
    {
        usa: "5½", china: 21, europe: 21, russia: 20, japan: 13, brazil: 19,
        uk: "4½", southKorea: 127, mexico: null, australia: "4½", mondopoint: 127,
        centimeters: 12.7, inches: 5
    },
    {
        usa: 6, china: 21.5, europe: 22, russia: 21, japan: 13.5, brazil: 20,
        uk: 5, southKorea: 130, mexico: 13, australia: 5, mondopoint: 130,
        centimeters: 13, inches: "5⅛"
    },
    {
        usa: "6½", china: 22.5, europe: "22½", russia: 22, japan: 14, brazil: 20.5,
        uk: "5½", southKorea: 133, mexico: null, australia: "5½", mondopoint: 133,
        centimeters: 13.3, inches: "5¼"
    },
    {
        usa: 7, china: 23, europe: 23, russia: 22.5, japan: 14.5, brazil: 21,
        uk: 6, southKorea: 140, mexico: 14, australia: 6, mondopoint: 140,
        centimeters: 14, inches: "5½"
    },
    {
        usa: "7½", china: 23.5, europe: "23½", russia: 23, japan: 15, brazil: 21.5,
        uk: "6½", southKorea: 143, mexico: null, australia: "6½", mondopoint: 143,
        centimeters: 14.3, inches: "5⅝"
    },
    {
        usa: 8, china: 24.5, europe: 24, russia: 23, japan: 15.5, brazil: 22,
        uk: 7, southKorea: 146, mexico: 15, australia: 7, mondopoint: 146,
        centimeters: 14.6, inches: "5¾"
    },
    {
        usa: "8½", china: 25, europe: "24½", russia: 24, japan: 15.5, brazil: 22.5,
        uk: "7½", southKorea: 152, mexico: null, australia: "7½", mondopoint: 152,
        centimeters: 15.2, inches: 6
    },
    {
        usa: 9, china: 25.5, europe: 25, russia: 25, japan: 16, brazil: 23,
        uk: 8, southKorea: 156, mexico: 15.5, australia: 8, mondopoint: 156,
        centimeters: 15.6, inches: "6⅛"
    },
    {
        usa: "9½", china: 26, europe: 26, russia: 25.5, japan: 16.5, brazil: 24,
        uk: "8½", southKorea: 159, mexico: null, australia: "8½", mondopoint: 159,
        centimeters: 15.9, inches: "6¼"
    },
    {
        usa: 10, china: 27, europe: 27, russia: 26, japan: 17, brazil: 25,
        uk: 9, southKorea: 165, mexico: 16, australia: 9, mondopoint: 165,
        centimeters: 16.5, inches: "6½"
    },
    {
        usa: "10½", china: 27.5, europe: "27½", russia: 27, japan: 17.5, brazil: 25.5,
        uk: "9½", southKorea: 168, mexico: null, australia: "9½", mondopoint: 168,
        centimeters: 16.8, inches: "6⅝"
    },
    {
        usa: 11, china: 28, europe: 28, russia: 27, japan: 18, brazil: 26,
        uk: 10, southKorea: 171, mexico: 17, australia: 10, mondopoint: 171,
        centimeters: 17.1, inches: "6¾"
    },
    {
        usa: "11½", china: 28.5, europe: 29, russia: 28, japan: 18.5, brazil: 27,
        uk: "10½", southKorea: 178, mexico: null, australia: "10½", mondopoint: 178,
        centimeters: 17.8, inches: 7
    },
    {
        usa: 12, china: 29, europe: 30, russia: 28.5, japan: 18.5, brazil: 28,
        uk: 11, southKorea: 181, mexico: 18, australia: 11, mondopoint: 181,
        centimeters: 18.1, inches: "7⅛"
    },
    {
        usa: "12½", china: 30, europe: "30½", russia: 29, japan: 19, brazil: 28.5,
        uk: "11½", southKorea: 184, mexico: null, australia: "11½", mondopoint: 184,
        centimeters: 18.4, inches: "7¼"
    },
    {
        usa: 13, china: 30.5, europe: 31, russia: 29.5, japan: 19.5, brazil: 29,
        uk: 12, southKorea: 190, mexico: 19, australia: 12, mondopoint: 190,
        centimeters: 19, inches: "7½"
    }
];
