"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const sizes_1 = require("./sizes");
class ShoesizeCtrl {
    constructor($scope, $timeout, $filter, ModalServiceFactory, ConfigService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.genderList = [
            {
                kind: 'male',
                title: this.$filter('translate')('Men'),
                sizeChart: sizes_1.manSizeChart
            },
            {
                kind: 'female',
                title: this.$filter('translate')('Women'),
                sizeChart: sizes_1.womanSizeChart
            },
            {
                kind: 'child',
                title: this.$filter('translate')('Kids'),
                sizeChart: sizes_1.childsizechart
            },
        ];
        this.option = JSON.parse(localStorage.getItem(`${this.constructor.name}_option`) || 'null') || {
            gender: 'female',
            size: 'usa',
        };
        this.sizesList = [
            {
                kind: 'usa',
                title: this.$filter('translate')('USA'),
                code: 'US'
            },
            {
                kind: 'china',
                title: this.$filter('translate')('China'),
                code: 'CN'
            },
            {
                kind: 'europe',
                title: this.$filter('translate')('Europe'),
                code: 'EU'
            },
            {
                kind: 'russia',
                title: this.$filter('translate')('Russia'),
                code: 'RU'
            },
            {
                kind: 'japan',
                title: this.$filter('translate')('Japan'),
                code: 'JP'
            },
            {
                kind: 'brazil',
                title: this.$filter('translate')('Brazil'),
                code: 'BR'
            },
            {
                kind: 'uk',
                title: this.$filter('translate')('United Kingdom'),
                code: 'UK'
            },
            {
                kind: 'southKorea',
                title: this.$filter('translate')('South Korea'),
                code: 'KO'
            },
            {
                kind: 'mexico',
                title: this.$filter('translate')('Mexico'),
                code: 'MX'
            },
            {
                kind: 'australia',
                title: this.$filter('translate')('Australia'),
                code: 'AU'
            },
            {
                kind: 'mondopoint',
                title: this.$filter('translate')('Mondopoint'),
                code: 'mm'
            },
            {
                kind: 'centimeters',
                title: this.$filter('translate')('Centimeters'),
                code: 'cm'
            },
            {
                kind: 'inches',
                title: this.$filter('translate')('Inches'),
                code: 'in'
            },
        ];
        this.hasError = false;
        this.minMax = {};
        this.data = { value: undefined };
        this.genderList.forEach((item) => {
            const maxList = {};
            const minList = {};
            item.sizeChart.forEach((kv) => {
                for (const k in kv) {
                    const value = this._parseInt(kv[k]);
                    // if (k == 'uk') {
                    //     console.log(value, kv[k])
                    // }
                    if (value >= 0) {
                        maxList[k] = maxList.hasOwnProperty(k) ? Math.max(value, maxList[k]) : value;
                        minList[k] = minList.hasOwnProperty(k) ? Math.min(value, minList[k]) : value;
                    }
                }
            });
            this.minMax[item.kind] = {
                minList: minList,
                maxList: maxList,
            };
        });
        console.debug(this.minMax);
    }
    $onInit() {
    }
    setSize(kind) {
        this.option.size = this.sizesList.filter((item) => item.kind == kind)[0].kind;
        localStorage.setItem(`${this.constructor.name}_option`, JSON.stringify(this.option));
    }
    setGender(gender) {
        this.option.gender = this.genderList.filter((item) => item.kind == gender)[0].kind;
        localStorage.setItem(`${this.constructor.name}_option`, JSON.stringify(this.option));
    }
    submitForm($form) {
        this.result = undefined;
        this.hasError = false;
        if ($form.$valid && (this.data.value != undefined)) {
            const gender = this.genderList.filter((item) => item.kind == this.option.gender)[0];
            for (const i in gender.sizeChart) {
                const value = this._parseInt(gender.sizeChart[i][this.option.size]);
                if (value >= this.data.value) {
                    this.result = [];
                    this.sizesList.forEach((item) => {
                        this.result.push(Object.assign(Object.assign({}, item), { size: gender.sizeChart[i][item.kind], value: this._parseInt(gender.sizeChart[i][item.kind]) }));
                    });
                    break;
                }
            }
        }
        else {
            this.hasError = true;
        }
    }
    clear($form) {
        if ($form) {
            $form.$setPristine();
            $form.$setUntouched();
        }
        this.result = undefined;
        this.data.value = undefined;
    }
    _parseInt(value) {
        if (value == null) {
            return -1;
        }
        if (typeof value === 'number') {
            return value;
        }
        const m = {
            '⅛': 1 / 8,
            '⅝': 5 / 8,
            '¾': 3 / 4,
            '½': 1 / 2,
            '¼': 1 / 4
        };
        for (const a in m) {
            if (value.indexOf(a) >= 0) {
                return parseInt(value.replace(a, '') || '0') + m[a];
            }
        }
        const f = parseFloat(value);
        const i = parseInt(value);
        return f == i ? i : f;
    }
}
ShoesizeCtrl.$inject = ['$scope', '$timeout', '$filter', 'ModalServiceFactory', 'ConfigService'];
const appModule = ng.module('app');
appModule.component('gameShoesize', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: ShoesizeCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('shoesize/');
    }]);
